import {
  GOVUKInitAll,
  HMRCInitAll,
  ClearCookies,
} from 'dvla-external-frontend';

// ensure the stylesheets are included in the compiled pack
require.context('./stylesheets/', true, /^\.\/[^_].*\.(css|scss|sass)$/i);
// ensure images are included in the compiled pack
require.context('./images', true);
// ensure dvla-external-frontend/dist/media are included in the compiled pack
require.context('dvla-external-frontend/dist/media', true);

// initialise javascript
GOVUKInitAll();
HMRCInitAll();
ClearCookies.init();
